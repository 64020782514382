import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import List from '../../components/List'
import LinksMenu from '../../components/LinksMenu'
import LayoutListView from '../../components/LayoutListView'

const Podcasts = ({ location }) => {
  const configID = '4tPzK4ehDr0KYD0jVrtRqP'
  const queryData = useStaticQuery(graphql`
    {
      allContentfulPodcast(sort: { fields: createdAt, order: DESC }) {
        edges {
          node {
            id
            title
            slug
            author
            url
            category
            podcastImage {
              file {
                url
              }
            }
            createdAt(formatString: "MMMM Do YYYY")
            summary {
              json
            }
          }
        }
      }
    }
  `)

  const TYPE = 'Podcast'
  const IMAGE_HEIGHT = 250
  const data = queryData.allContentfulPodcast.edges.map(obj => ({
    id: obj.node.id,
    title: obj.node.title,
    body: obj.node.author,
    image: obj.node.podcastImage,
    imageHeight: IMAGE_HEIGHT,
    slug: `/resources/podcasts/${obj.node.slug}`,
    category: obj.node.category,
    type: TYPE
  }))

  const KEY_CATEGORY = 'category'
  const [filteredData, setFilteredData] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('All Categories')

  const categories = Array.from(new Set(data.map(item => item[KEY_CATEGORY]).flat()))

  useEffect(() => {
    setFilteredData(data)
  }, [])

  function handleClick(e) {
    const { id: value, type } = e.currentTarget.dataset
    setSelectedCategory(value)
    const filteredArr = data.filter(item => value.includes('All') || item[KEY_CATEGORY] === value)
    setFilteredData(filteredArr)
  }

  return (
    <LayoutListView path={location.pathname} configID={configID}>
      <LinksMenu
        title="All Categories"
        items={categories}
        type="state"
        handleClick={handleClick}
        resetFilter={handleClick}
        selectedValue={selectedCategory}
      />
      <List data={filteredData} itemType="subMenu" className="items-container" />
    </LayoutListView>
  )
}

export default Podcasts
